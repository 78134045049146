import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '@/models/user';
import UserComponent from './user/User.vue';
import { GenericService } from '@/services/generic';
import { Group } from '@/models/group';
import { CreateUser } from '@/models/createUser';
import { Role } from '@/models/role';
import EditUserComponent from './edit-user/EditUser.vue';
import customButton from '../../components/general-components/custom-buttom/customButton.vue';
import paginatorComponent from '../../components/general-components/paginator-component/paginatorComponent.vue';
import { EditUser } from '@/models/editUser';
import Swal from 'sweetalert2';
import { CONSTANTS } from '@/core/contants';

@Component({
    components: {
        'user': UserComponent,
        'edit-user': EditUserComponent,
        'paginator-component':paginatorComponent,
        'custom-button': customButton
    },
})
export default class UsersComponent extends Vue {
    public users: User[] = [];
    public groups: Group[] = [];
    public roles: Role[] = [];
    public createdUser: CreateUser = new CreateUser();
    public selectedUser: EditUser = new EditUser();
    public items: any = [];
    public moment: any = require('moment');
    public customFilters: string = '';
    public isLoading: boolean = false;
    public filterFields = {
        userName: '',
        name:''
    };
    public updateByLocalChange: boolean = false;
    
    @Prop({ default: false, type: Boolean }) public updateData!: boolean;

    public fields = [
        {
            key: 'fullName',
            label: 'Name'
        },
        {
            key: 'email',
            label: 'User'
        },
       
        {
            key: 'Actions',
            label: 'Actions'
        }];


    public async mounted() {
        this.$emit('toggleTemplate', false);
        [this.users, this.groups, this.roles] = await Promise.all(
        [
            GenericService.getAll<User>('users'),
            GenericService.getAll<Group>('group'),
            GenericService.getAll<Role>('roles'),
        ]);
    }

    public addUser(user: User) {
        this.updateByLocalChange = !this.updateByLocalChange;
        this.$bvModal.hide('user-modal');
    }

    public userUpdated(){
        this.updateByLocalChange = !this.updateByLocalChange;
        this.$bvModal.hide('edit-modal');
    }

    public editUser(user: EditUser) {
        this.selectedUser = user;
        this.selectedUser.roles = new Array<Role>();
        this.$bvModal.show('edit-modal');
    }
    public async disabledUser(user:any) {

        var text = user.enabled ? 'Disabled' : 'Enabled';
        Swal.fire({
            title: 'Are you sure?',
           
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C9FAF',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result: any) => {
            if (result.value && user.id) {
                GenericService.put('users/EnabledDisabled',{}, user.id).then((response: any) => {
                    debugger;
                    user.enabled = !user.enabled;
                    Swal.fire(text, `This Entry has been ${text}.`, 'success');
               
                }).catch((error) => {
                    Swal.fire({
                        type: 'warning',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    });

                });
            }
        });
    }

    private manageData(data: any) {
        this.items = [];
        for (const obj of data) {           

            obj['fullName'] = obj.firstName + ' '+ obj.lastName;
            for(const comp of obj.companies){
                if(comp.companyId =  this.$store.getters.getCurrentCompany.toUpperCase()){
                    obj['enabled'] = comp.enabled;
                }
            }
        }
        this.items = data;
    }

    private search(){

        let customUrl = '?$filter=';
        this.customFilters = '';
        if (
            this.filterFields.userName !== ''
        ) {
            customUrl += `contains(tolower(UserName),tolower('${this.filterFields.userName}')) or contains(tolower(FirstName), tolower('${this.filterFields.userName}')) or contains(tolower(LastName),tolower('${this.filterFields.userName}'))`;
        }
        this.customFilters = customUrl;
    }

   

    public clearCustomFilter() {
        this.filterFields.userName = '';
        this.filterFields.name='';
    }

  
}
