import { Vue, Component, Prop } from 'vue-property-decorator';
import { Register } from '@/models/register';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { GenericService } from '@/services/generic';
import VueRecaptcha from 'vue-recaptcha';


@Component({
    components: {
        VueRecaptcha
    }
})
export default class RegisterComponent extends Vue {
    public verified = false;
    public loading = false;
    public register = new Register();
    public repeat = '';

    get usernameState() {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return (this.register.email.length > 0 && re.test(this.register.email)) ? true : false;
    }

    get firstNameState() {
        return this.register.firstName.length > 0;
    }

    get lastNameState() {
        return this.register.lastName.length > 0;
    }

    get passwordState() {
        return this.register.password.length > 0;
    }

    get repeatPasswordState() {
        return this.repeat.length > 0;
    }

    get validationForm() {
        return this.firstNameState && this.lastNameState && this.passwordState && this.repeatPasswordState && this.verified;
    }

    public get reCaptchaKey() {
        return process.env.VUE_APP_RECAPTCHA_KEY;
    }
    public verify(response: any) {
        this.verified = true;
    }
    public expire() {
        this.verified = false;
    }
    public async mounted() {
        this.$emit('toggleTemplate', true);
        if (!localStorage.getItem('signupHash')) { this.$router.push('/login'); }
    }
    public async registerUser() {
        this.loading = true;

        if (this.register.password.length < 7) {
            Swal.fire('Password incorrect', 'Password must be at least 7 characters');
            return;
        }

        if (this.register.password !== this.repeat) {
            Swal.fire('Invalid form', 'Passwords do not match')
            return;
        }

        const hash = localStorage.getItem('signupHash');
        if (!hash) { return; }
        this.register.hash = hash;

        GenericService.post('register', this.register).then((response: any) => {
            Swal.fire('User Registered', 'You will be redirect to our online system to complete your payment');
            this.$router.push('/login');
        }).catch((error: any) => {
            Swal.fire('Error', 'This user already exists', 'error');
        }).finally(() => this.loading = false);
    }
}
