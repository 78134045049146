import { Role } from './role';

export class CreateUser {
    public email: string;
    public firstName: string;
    public lastName: string;
    public roles: Role[];
    public phoneNumber: string;
    public companyId: string;
}
