import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import './components';
import './custom.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'sweetalert2/src/sweetalert2.scss';
import Axios from 'axios';
import { CONSTANTS } from './core/contants';
import Multiselect from 'vue-multiselect';
import UserApplicacion from './models/UserApplication';
import Swal from 'sweetalert2';
import { functions } from './shared/functions';
// import VueRecaptcha from 'vue-recaptcha';

// Vue.component('vue-recaptcha', VueRecaptcha);

Vue.filter('formatDate', (value: any) => {
  var moment: any = require('moment');
  if (value != undefined) {
    value = moment(value).format('YYYY-MM-DD');
  }
  return value;
});

Vue.filter('formatDateHour', (value: any) => {
  var moment: any = require('moment');
  if (value != undefined) {
    value = moment(value).format('LLLL');
  }
  return value;
});


Vue.filter('toCurrency', (value: any) => {
  var realValue = Number(value);
  if (typeof realValue !== 'number') {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.directive('canAccess', function (el, binding) {

  var splitRoles = binding.value.permissions.split(',');

  let currentUser = localStorage.getItem(CONSTANTS.user)
    ? JSON.parse(atob(localStorage.getItem(CONSTANTS.user) as string) as any) as UserApplicacion : null;

  if (currentUser != null) {

    const administratorRole = currentUser.companies.filter(f => f.roles.filter(a => a.name == 'Administrator').length > 0);
    if (administratorRole.length ==0) {

      let currentCompany = localStorage.getItem(CONSTANTS.currentCompany);
      if (currentCompany) {
        const result = currentUser.companies.filter(f => f.companyId == currentCompany);

        if (result.length > 0) {
          var anyRole = result[0].roles.filter(f => {
            return splitRoles.filter((a: any) => a == f.name).length > 0;
          });
          if (anyRole.length == 0) {
            if (binding.value.visible) {
              el.style.color = '#717384';
              el.style.cursor = 'not-allowed';
              el.style.opacity = '0.5';
              el.style.textDecoration = 'none';
              el.style.pointerEvents = 'none';
              el.title = 'you don\'t have permission to perform this action';
            }
            else {
              el.style.display = 'none';
            }
          }
        }
      }
    }
  }
});


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.component('multiselect', Multiselect);

Axios.interceptors.request.use((req) => {

  let currentUser = localStorage.getItem(CONSTANTS.user)
    ? JSON.parse(atob(localStorage.getItem(CONSTANTS.user) as string) as any) as UserApplicacion : null;
  if (currentUser) {
    req.headers.Authorization = `Bearer ${currentUser.token}`;
  }

  var currentCompany = localStorage.getItem(CONSTANTS.currentCompany);
  if (currentCompany) {
    req.headers.currentCompany = currentCompany;
  }

  return req;
});

Axios.interceptors.response.use((req) => {
  return req;
}, (error: any) => {
  if (error.response.status == 400) {
    var errors = functions.validationErrorsToString(error.response.data);
    Swal.fire('Error', errors, 'error');
  }
  if (error.response.status === 418) {
    window.location.pathname = '/login';
  }
  else if (error.response.status === 403) {
    Swal.fire('Forbidden', 'you don\'t have permission to perform this action', 'error');
    console.log(location.pathname);
  }
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
