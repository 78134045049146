import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CustomButton extends Vue {
    @Prop ({default: false, type: Boolean}) public isLoading!: boolean;
    @Prop (String) public label!: string;
    @Prop (String) public size!: string;
    @Prop (String) public customVariant!: string;
    @Prop (String) public customStyle!: string;
    @Prop (String) public icon!: string;
    public emit() {
        this.$emit('onClick', true);
    }
}
