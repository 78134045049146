import Vue from 'vue';
import Router, { RouterMode } from 'vue-router';
import { CONSTANTS } from '@/core/contants';
import UserApplicacion from '@/models/UserApplication';
import UsersComponent from '../views/users/Users.vue';
import RolesComponent from '../views/roles/Roles.vue';
import ResetComponent from '../views/reset/Reset.vue';
import HashComponent from '../views/hash/Hash.vue';
import RegisterComponent from '../views/register/Register.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Home.vue'),
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.userRole, CONSTANTS.adminRole, CONSTANTS.supervisor, CONSTANTS.Accounting, CONSTANTS.ITRole]
      }
    },
    {
      path: '/pay/:hashLink',
      name: 'pay',
      component: () => import('../views/payView/Pay.vue'),
      meta: {
        isAuthView: true
      }
    },
    {
      path: '/redirect',
      name: 'redirect',
      component: () => import('../views/redirectView/Redirect.vue'),
      meta: {
        isAuthView: true
      }
    },
    {
      path: '/noFound',
      name: 'noFound',
      component: () => import('../views/noFoundView/NoFound.vue'),
      meta: {
        isAuthView: true
      }

    },
    {
      path: '/notAuthorized',
      name: 'notAuthorized',
      component: () => import('../views/notAuthorized/notAuthorized.vue'),
      meta: {
        isAuthView: true
      }

    },
    {
      path: '/Company',
      name: 'Company',
      component: () => import('../views/company/Company.vue'),
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.adminRole]
      },
    },
    {
      path: '/Transactions',
      name: 'Transactions',
      component: () => import('../views/transactions/Transactions.vue'),
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.userRole, CONSTANTS.adminRole, CONSTANTS.supervisor, CONSTANTS.ITRole, CONSTANTS.Accounting]
      },
    },
    {
      path: '/Logs',
      name: 'Logs',
      component: () => import('../views/logs/logs.vue'),
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.adminRole]
      },
    },
    {
      path: '/users',
      name: 'Users',
      component: UsersComponent,
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.adminRole, CONSTANTS.ITRole]
      },
    },
    {
      path: '/roles',
      name: 'Roles',
      component: RolesComponent,
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.adminRole, CONSTANTS.ITRole]
      },
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: () => import('../views/signup/signup.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login/login.vue'),
      meta: {
        isAuthView: true
      }

    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/userProfile/userProfile.vue'),
      meta: {
        requiresAuth: true,
        roles: [CONSTANTS.adminRole, CONSTANTS.supervisor, CONSTANTS.userRole, CONSTANTS.Accounting, CONSTANTS.ITRole]
      },

    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetComponent,
      meta: {
        isAuthView: true
      }

    },
    {
      path: '/resetpassword/:hash',
      name: 'resetpassword',
      component: HashComponent,
      meta: {
        isAuthView: true
      },
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterComponent,
      meta: {
        isAuthView: true
      }
    }
  ],
});

router.beforeEach((to, from, next) => {
  const toRoles = to.meta.roles as string[];
  const userBase = localStorage.getItem(CONSTANTS.user);
  const currentCompany = localStorage.getItem(CONSTANTS.currentCompany);
  if (to.meta.requiresAuth) {
    if (!userBase) {
      next({
        name: 'login',
        params: {
          nextUrl: to.name ? to.name : to.fullPath,
        },
      });
    } else {
      next();
      const currentUser = JSON.parse(atob(userBase)) as UserApplicacion;
      let currentCompany = localStorage.getItem(CONSTANTS.currentCompany);
      if (currentCompany == undefined) {
        next();
      }
      else {        
        if (currentUser.companies.length == 1) {
          const result = currentUser.companies.filter(f => f.companyId == currentCompany);
          if (result.length > 0) {
            var anyRole = result[0].roles.filter(f => {
              return toRoles.filter((a: any) => a == f.name).length > 0;
            });
            if (anyRole.length == 0) {
              next({ name: 'notAuthorized' });
            }
          }
          else {
            next({ name: 'notAuthorized' });
          }
          next();
        }
        else{
          const result = currentUser.companies.filter(f => f.roles.filter(a=>a.name != 'Visitor').length > 0);
          if(result.length>0){
            var anyRole = result[0].roles.filter(f => {              
              return toRoles.filter((a: any) => a == f.name).length > 0;
            });
            if (anyRole.length == 0) {
              next({ name: 'notAuthorized' });
            }            
          }
          else {
            next({ name: 'notAuthorized' });
          }          
          router.app.$store.commit(CONSTANTS.currentCompany, result[0].companyId);                  
          next();
        }
      }
    }
  } else {
    if (!userBase && to.name !== 'login' && !to.meta.isAuthView) {
      next({
        name: 'login',
        params: {
          nextUrl: to.name ? to.name : to.fullPath,
        },
      });
    } else {
      next();
    }
  }
});

export default router;
