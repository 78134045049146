import axios from 'axios';
import fileDownload from 'js-file-download';
import { CONSTANTS } from '@/core/contants';

const url = process.env.VUE_APP_BASE_URL;

export class GenericService {

    public static async getFile<T>(action: string, fileIdentifier: string) {


        const resp = await axios.get<T>(`${url}/api/${action}/${fileIdentifier}`);
        const data = resp.data;
        return data;
    }
    public static async getByParameter<T>(customParam: string) {
        const resp = await axios.get<T>(`${url}/api/${customParam}`);
        const data = resp.data;
        return data;
    }
    public static async get<T>(action: string, id: number | string) {
        const resp = await axios.get<T>(`${url}/api/${action}/${id}`);
        const data = resp.data;
        return data;
    }
    public static async getAll<T>(action: string) {
        const resp = await axios.get<T[]>(`${url}/api/${action}`);
        const data = resp.data;
        return data;
    }
    public static async downloadFile(action: string, name: string) {
        const resp = await axios.get(`${url}/api/${action}`, {
            responseType: 'blob',
        });
        const windowUrl = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = windowUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }



    public static async post<T>(action: string, entity: T) {
        const resp = await axios.post<T>(`${url}/api/${action}`, entity);
        const data = resp.data;
        return data;
    }
    public static async put<T>(action: string, entity: T, id: number | string) {
        await axios.put<void>(`${url}/api/${action}/${id}`, entity);
    }
    public static async delete(action: string, id: number | string) {
        await axios.delete(`${url}/api/${action}/${id}`);
    }
}
