import Vue from 'vue';
import Vuex from 'vuex';
import * as Msal from 'msal';
import UserApplicacion, { IUserApplicacion } from '@/models/UserApplication';
import { CONSTANTS } from '@/core/contants';
import { Group } from '../models/group';
import { GenericService } from '../services/generic';

Vue.use(Vuex);

interface ILoginActionsParams {
  user: Msal.User;
  token: string;
}

interface IRequestAccountResponse {
  data: UserApplicacion;
}

export default new Vuex.Store({
  state: {
    currentUser: localStorage.getItem(CONSTANTS.user)
      ? JSON.parse(atob(localStorage.getItem(CONSTANTS.user) as string) as any) as UserApplicacion
      : new UserApplicacion('', '', [], '', 'false', [], ''),
    userClaims: ['newUser', 'oid', 'name', 'given_name'],
    loading: false,
    currentCompany: localStorage.getItem(CONSTANTS.currentCompany),
    currentMerchantId: localStorage.getItem(CONSTANTS.currentMerchantId)
  },
  getters: {
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    isUserLogged: (state) => {
      return '';
    },
    getCurrentCompany: (state) => {
      return state.currentCompany;
    },
    getCurrentMerchantId: (state) =>{
      return state.currentMerchantId
    }
  },
  mutations: {
    [CONSTANTS.saveUser](state, user: UserApplicacion) {
      const userEncode = btoa(JSON.stringify(user));

      localStorage.setItem(CONSTANTS.user, userEncode);
      state.currentUser = user;
    },
    [CONSTANTS.logOut](state) {
      localStorage.removeItem(CONSTANTS.user);

      Object.assign({}, { ...state.currentUser, ...new UserApplicacion('', '', [], '', '', [], '') });
    },
    [CONSTANTS.updateToken](state, token) {
      debugger;
      const userBase = localStorage.getItem('uif') as string;
      const user = JSON.parse(atob(userBase)) as UserApplicacion;
      user.token = token;

      const userEncode = btoa(JSON.stringify(user));
      localStorage.setItem(CONSTANTS.user, userEncode);
      state.currentUser = user;

    },
    [CONSTANTS.currentCompany](state, companyid) {
      localStorage.setItem(CONSTANTS.currentCompany, companyid);
      state.currentCompany = companyid;
    },
    [CONSTANTS.currentMerchantId](state, merchantId) {
      localStorage.setItem(CONSTANTS.currentMerchantId, merchantId);
      state.currentMerchantId = merchantId;
    },
    [CONSTANTS.UpdateCompanies](state, response) {

      const userBase = localStorage.getItem('uif') as string;
      const user = JSON.parse(atob(userBase)) as UserApplicacion;
      if (response.success) {
        user.token = response.token;
        if (response.user.companies.length > 0) {
          user.companies = response.user.companies;
          const userEncode = btoa(JSON.stringify(user));
         
          localStorage.setItem(CONSTANTS.user, userEncode);
          state.currentUser = user;
       //   state.currentCompany = response.user.companies[0].companyid;
        }
      } else {
        localStorage.removeItem(CONSTANTS.user);
        Object.assign({}, { ...state.currentUser, ...new UserApplicacion('', '', [], '', '', [], '') });
        window.location.pathname = '/login';
      }
    }
  },
  actions: {
    loginOrSignUpUser({ commit, state }, { user, token }: ILoginActionsParams) {
      return new Promise((resolve, reject) => {

        const userCreatedOrLogged = user as any;
        const registerOrLogedUser = new UserApplicacion('', '', [], token, '', [], '');

        registerOrLogedUser.name = userCreatedOrLogged.firstName;
        registerOrLogedUser.lastName = userCreatedOrLogged.lastName;
        registerOrLogedUser.token = token;
        registerOrLogedUser.email = userCreatedOrLogged.email;
        registerOrLogedUser.roles = userCreatedOrLogged.roles;
        userCreatedOrLogged.companies.forEach((element: Group) => {
          registerOrLogedUser.companies.push(element);
        });
       commit(CONSTANTS.currentCompany, registerOrLogedUser.companies[0].companyId);
       commit(CONSTANTS.currentMerchantId, registerOrLogedUser.companies[0].merchantId);
        registerOrLogedUser.companies = userCreatedOrLogged.companies;
        registerOrLogedUser.phoneNumber = userCreatedOrLogged.phoneNumber;

        commit(CONSTANTS.saveUser, registerOrLogedUser);
        resolve(registerOrLogedUser);
      });
    },

  },
});
