import { Vue, Component, Prop } from 'vue-property-decorator';
import { CONSTANTS } from '@/core/contants';
import UserApplicacion from '@/models/UserApplication';

@Component
export default class SidebarComponent extends Vue {
    public isMenuOpened = true;
 
    public tooggleMenu() {

    }
}
