import { Role } from './role';

export class EditUser {
    public id: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public roles: Role[]  = [];
}
