import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@/models/user';
import { GenericService } from '@/services/generic';
import { Group } from '@/models/group';
import { Role } from '@/models/role';
import { EditUser } from '@/models/editUser';
import customButton from '../../../components/general-components/custom-buttom/customButton.vue';
import Swal from 'sweetalert2';
import { functions } from '@/shared/functions';


@Component({
    components: { 'custom-button': customButton }
})

export default class EditUserComponent extends Vue {
    @Prop() public groups: Group[];
    @Prop() public roles: Role[];

    @Prop() public entity: EditUser;
    public isLoading: boolean = false;
    public selectRoles: Role[] = [];

    mounted() {
    }
    get firstNameState() {
        return (this.entity.firstName.length > 0) ? true : false;
    }

    get lastNameState() {
        return (this.entity.lastName.length > 0) ? true : false;
    }

    get validateForm() {
        return !(this.firstNameState && this.lastNameState);
    }
    @Watch('entity', { immediate: true, deep: true })
    public fillEdit(user: EditUser) {

        GenericService.getAll('users/RolesByNameAndCia?userid=' + user.id + '&companyid=' + this.$store.getters.getCurrentCompany).then((response: any) => {
            response.forEach((element: any) => {
                let role = new Role();
                role.id = element.id;
                role.name = element.name;
                this.selectRoles.push(role);
            });

        });
    }

    public async edit() {
        if (this.selectRoles.length == 0) {

            Swal.fire('Roles', 'You must select at least one role', 'warning');
            return;
        }

        this.entity.roles = this.selectRoles;
        await GenericService.put('users', this.entity, this.entity.id).then((response: any) => {
            this.selectRoles = [];
            Swal.fire('Success', 'Your work has been saved', 'success');
            this.$emit('user-updated', response);
        }).catch((error: any) => {
            if (error.response.status == 400) {
                var errors = functions.validationErrorsToString(error.response.data);
                Swal.fire('Error', errors, 'error');
            }
            else {
                Swal.fire('Error', 'Error updating user', 'error');
            }
        }).finally(() => this.isLoading = false);;
    }

    public closeModal() {

        this.$bvModal.hide('edit-modal');
    }
}
