import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericService } from '@/services/generic';
import Swal from 'sweetalert2';
import 'vee-validate';

@Component
export default class HashComponent extends Vue {
    public isLoading = false;
    public password = '';
    public confirmPassword = '';

    public async mounted() {
        debugger;
        this.$emit('toggleTemplate', true);
        const hash = this.$route.params.hash;
        
        await GenericService.post('password/checkhash', { hash: hash }).then((response: any) => {
            if (!response.valid) {
                this.$router.push('/login');
            }
        });
    }

    get passwordState() {
        return this.password.length > 0;
    }

    get confirmPasswordState() {
        return this.confirmPassword.length > 0;
    }
    get bothPasswordAreSame() {
        return this.password === this.confirmPassword;
    }
    get emptyForm() {
        return !(this.passwordState && this.confirmPasswordState && this.bothPasswordAreSame);
    }

    public async resetPassword() {
        if (!this.bothPasswordAreSame) {
            Swal.fire('Error', 'Both passwords are not the same', 'error');
            return;
        }
        this.isLoading = true;
        if (!this.emptyForm) {
            const hash = this.$route.params.hash;
            await GenericService.post(`password/reset`, { hash, password: this.password }).then((response: any) => {
                if (response.valid) {
                    Swal.fire('Success', 'Password changed successfully', 'success');
                    this.$router.push({
                        name: 'login',
                    });
                }
                else {
                    Swal.fire('Error', response.error, 'error');
                }
            }).catch((error) => {
                Swal.fire('Error', 'Failed to reset password', 'error');
                this.$router.push('/login');
            }).finally(() => this.isLoading = false);
        }
    }
}
