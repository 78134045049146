import { GenericService } from '@/services/generic';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Swal from 'sweetalert2';

@Component
export default class PaginatorComponent extends Vue {
    @Prop (String) public entity!: string;
    @Prop ({default: 1, type: Number}) public currentPage!: number;
    @Prop ({default: 10, type: Number}) public perPage!: number;
    @Prop ({default: '?$filter=', type: String}) public filters!: string;
    @Prop ({default: 'Id', type: String}) public orderBy!: string;
    @Prop ({default: false, type: Boolean}) public refreshComponent!: boolean;
    public currentPage_: number = 1;

    private rows_: number = 0;
    private url!: string;
    private mounted() {
        this.calculatePages();
    }
    @Watch('currentPage_', {deep: true})
    private onCurrentPageChange(value) {
        this.currentPage = value;
        this.calculatePages();
    }
    @Watch('filters', {deep: true})
    private onFiltersChangeChange() {
        this.currentPage = 1; // Se puso el uno para que siempre que busque con filtro inicie en la pagina 1
        this.calculatePages();
    }
    private calculatePages() {
        this.$emit('startLoading');
        const skip = (this.perPage * (this.currentPage - 1));
        const top = this.perPage;
        const complexUrl = `$skip=${skip}&$top=${top}&$orderby=${this.orderBy} desc&$count=true`;
        if (this.filters !== '' && this.filters !== '?$filter=') {
            this.url = this.filters + `&${complexUrl}`;
        } else {
            this.url = `?${complexUrl}`;
        }
        this.url = this.entity + this.url;
        GenericService.getAll(this.url).then((response: any) => {
            if (response.data) {
                this.rows_ = response.count;
                this.$emit('onCurrentPageChange', response.data);
            } else {
                this.$emit('onCurrentPageChange', []);
            }
            this.$emit('stopLoading');
        }, (error) => {
            this.$emit('stopLoading');
        });
    }
    @Watch('refreshComponent')
    private onRefreshComponent() {
        this.calculatePages();
    }
}
