export abstract class functions {

    public static toCurrency(value: any) {
        if (typeof value !== 'number') {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    }

    public static validationErrorsToString(data: any): string{

        let validationErrorDictionary = data;
        var errors = '';
        for (var fieldName in validationErrorDictionary) {
            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                errors +=  validationErrorDictionary[fieldName] + '<br/>';
            }
        }
        return errors;
    }

    
}