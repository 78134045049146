export const  CONSTANTS = {
    user: 'uif',
    currentCompany:'CURRENT_COMPANY',
    saveUser: 'SAVE_USER',
    logOut: 'LOG_OUT',
    userRole: 'Agent',
    adminRole: 'Administrator',
    supervisor:'Supervisor',
    ITRole: 'IT',
    Accounting: 'Accounting',
    updateToken: 'UPDATE_USER_TOKEN',
    UpdateCompanies:'UPDATE_COMPANY',
    currentMerchantId: 'CURRENT_MERCHANTID'

};
