import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CONSTANTS } from '@/core/contants';
import UserApplicacion from '@/models/UserApplication';
import Swal from 'sweetalert2';
import { CardPlugin } from 'bootstrap-vue';


export class OptionElement {
    public value: string;
    public text: string;

}

@Component
export default class HeaderComponent extends Vue {
    public currentUser: UserApplicacion = null;
    public fullName: string = null;
    public companies: OptionElement[] = [];
    public currentCompany: string = '';

    mounted() {
        this.getCompanies();
    }

    private getCompanies() {

        this.companies = [];
        this.currentUser = localStorage.getItem(CONSTANTS.user)
            ? JSON.parse(atob(localStorage.getItem(CONSTANTS.user) as string) as any) as UserApplicacion : null;
        if (this.currentUser != null) {
            this.fullName = this.currentUser.name + ' ' + this.currentUser.lastName;
        }
        if (this.currentUser) {            
            if (this.currentUser.companies) {                
                this.currentUser.companies.filter(a=>a.roles.filter(f=>f.name!='Visitor').length > 0).forEach((Element: any) => {                    
                    this.companies.push({ value: Element.companyId, text: Element.name });
                });
                this.currentCompany = this.$store.getters.getCurrentCompany;
            }
        }
    }

    signout() {
        this.$store.commit(CONSTANTS.logOut);
        window.location.reload();
    }

    gotoProfile() {
        this.$router.push('/profile');
    }

    changeCompany(arg: any) {
        this.$store.commit(CONSTANTS.currentCompany, arg);        
        debugger;
        var merchantId = this.currentUser.companies.filter(f=>f.companyId == arg)[0].merchantId;
        console.log(merchantId);
        this.$store.commit(CONSTANTS.currentMerchantId, merchantId);       

        location.reload();
    }

}
