export class Register {
    public email: string;
    public firstName: string;
    public lastName: string;
    public password: string;
    public hash: string;

    constructor() {
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.password = '';
        this.hash = '';
    }
}
