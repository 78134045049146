import { Group } from './group';

export interface IUserApplicacion {
    name: string ;
    lastName: string;
    roles: string[];
    token: string;
    email: string;
    companies: Group[];
    phoneNumber: string;
  }

  

export default class UserApplicacion implements IUserApplicacion {
  public name: string ;
  public lastName: string;
  public roles: string[];
  public token: string;
  public email: string;
  public companies: Group[];
  public phoneNumber: string;
  constructor(
    name: string,
    lastName: string,
    role: string[],
    token: string,
    email: string,
    companies: Group[],
    phoneNumber: string,
  ) {
    this.name = name;
    this.lastName = lastName;
    this.roles = role;
    this.token = token;
    this.email = email;
    this.companies = companies;
    this.phoneNumber = phoneNumber;
  }
}
