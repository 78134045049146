import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenericService } from '@/services/generic';
import Swal from 'sweetalert2';

@Component
export default class ResetComponent extends Vue {
    public email = '';
    public loading = false;
    get usernameState() {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return (this.email.length > 0 && re.test(this.email)) ? true : false;
    }
    mounted() {
        this.$emit('toggleTemplate', true);
    }

    public async resetPassword() {
        this.loading = true;

        GenericService.post('password/request', { userEmail: this.email }).then((response: any) => {
            Swal.fire('Success', 'Your password has been reset', 'success');
            this.$router.push({
                name: 'login',
            });

        }).catch((error) => {
            Swal.fire('Error', 'Email not registered', 'error');
        }).finally(() => this.loading = false);

    }

    back() {
        this.$router.push({
            name: 'login',
        });
    }
}
