import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '@/models/user';
import { GenericService } from '@/services/generic';
import { Group } from '@/models/group';
import { CreateUser } from '@/models/createUser';
import { Role } from '@/models/role';
import customButton from '../../../components/general-components/custom-buttom/customButton.vue';
import Swal from 'sweetalert2';
import { functions } from '@/shared/functions';

@Component({
    components: { 'custom-button': customButton }
})

export default class UserComponent extends Vue {
    public user: CreateUser = {
        companyId: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        roles: []
    };
    @Prop() public groups: Group[];
    @Prop() public roles: Role[];
    public isLoading: boolean = false;

    get emailState() {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return (this.user.email.length > 0 && re.test(this.user.email)) ? true : false;
    }
    get firstNameState() {

        return (this.user.firstName.length > 0) ? true : false;
    }

    get lastNameState() {
        return (this.user.lastName.length > 0) ? true : false;
    }

    get validateForm() {
        return !(this.emailState && this.firstNameState && this.lastNameState);
    }

    mounted() {
        this.user.companyId = this.$store.getters.getCurrentCompany.toUpperCase();
    }

    public verifyIfUserExists(username: any) {
        console.log(this.user.email);
        GenericService.getAll(`Users/ExistsInOtherCia?username=${this.user.email}`).then((response: any) => {
            if (response) {
                this.user.firstName = response.firstName;
                this.user.lastName = response.lastName;
            }

        });
    }
    public save() {
        if (this.user.roles.length == 0) {

            Swal.fire('Roles', 'You must select at least one role', 'warning');
            return;
        }
        this.isLoading = true;


        GenericService.post('users', this.user).then((response: any) => {

            Swal.fire('Success', 'Your work has been saved', 'success');
            this.$emit('user-registered', response);
        }).catch((error: any) => {
            if (error.response.status != 400) {
                Swal.fire('Error', 'Error creating user', 'error');
            }

        }).finally(() => this.isLoading = false);
    }


    public closeModal() {

        this.$bvModal.hide('user-modal');
    }
}
