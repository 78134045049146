import { Vue, Component, Prop } from 'vue-property-decorator';
import SidebarComponent from '@/components/core-vue/sidebar/Sidebar.vue';
import HeaderComponent from '@/components/core-vue/header/Header.vue';
import FooterComponent from '@/components/core-vue/footer/Footer.vue';

@Component({
   components: {
      'app-sidebar': SidebarComponent,
      'app-header': HeaderComponent,
      'app-footer': FooterComponent,
   },
})
export default class CoreVue extends Vue {
   public isPayView: boolean = false;
   public updateCompanies: boolean = true;
   public initialTemplate: boolean = true;
   public toggleTemplate(toggleTemplate: boolean) {
      this.isPayView = toggleTemplate;
      this.initialTemplate = false;
   }

}
