import { Component, Vue } from 'vue-property-decorator';
import EventBus from '@/core/EventBus';
import * as Msal from 'msal';
import axios from 'axios';

import UserApplicacion, { IUserApplicacion } from '@/models/UserApplication';
import { CONSTANTS } from './core/contants';

@Component
export default class App extends Vue {

    constructor() {
        super();
    }

    public created() {
        this.$forceUpdate();
        EventBus.$on('onUserLoggedOrSignedUp', (user: Msal.User, token: string) => {            
            this.$store.dispatch('loginOrSignUpUser', { user, token }).then((user: IUserApplicacion) => {

                if (!token) {
                    this.$router.push({
                        name: 'home',
                    });
                }
            }).catch((error) => {
                console.error(error);
                // Logout User
                this.$store.commit(CONSTANTS.logOut);

            });
        });
    }

}
