import { render, staticRenderFns } from "./User.vue?vue&type=template&id=2892b568&"
import script from "./user.ts?vue&type=script&lang=ts&"
export * from "./user.ts?vue&type=script&lang=ts&"
import style0 from "./user.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports